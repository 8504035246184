var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[(_vm.$vuetify.breakpoint.mobile)?_c('v-data-iterator',{attrs:{"items":_vm.vendedores,"items-per-page":10,"search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_vm._l((items),function(vendedor,i){return [_c('mobile-vendedores',{key:'mobilev-' + i,attrs:{"vendedor":vendedor},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mx-2 py-4 px-2",attrs:{"depressed":"","text":"","color":"blue-grey","min-width":"23px","height":"31px","to":{
                      name: 'Vendedor',
                      params: { cod: vendedor.co_ven.trim() }
                    }}},[_c('v-icon',{attrs:{"left":"","color":"secondary","size":"20"}},[_vm._v("mdi-file-download-outline")]),_vm._v(" Horarios ")],1),_c('v-divider',{staticStyle:{"margin-top":"4px !important"},attrs:{"inset":"","vertical":""}}),_c('v-btn',{staticClass:"mx-2 py-4 px-2",attrs:{"depressed":"","text":"","color":"blue-grey","min-width":"23px","height":"31px","to":{
                      name: 'Vendedor',
                      params: { cod: vendedor.co_ven.trim() }
                    }}},[_c('v-icon',{attrs:{"left":"","size":"20"}},[_vm._v(" mdi-text-box-check-outline ")]),_vm._v(" Detalles ")],1)],1)]},proxy:true}],null,true)})]})]}},{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"list-item-two-line, list-item, list-item-two-line"}})]},proxy:true}],null,false,2117474179)}):_c('v-data-table',{attrs:{"headers":_vm.dataheaders,"items":_vm.vendedores,"search":_vm.search,"loading":_vm.loading,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.ven_des",fn:function(ref){
                    var item = ref.item;
return [_c('v-list-item',{attrs:{"to":{ name: 'Vendedor', params: { cod: item.co_ven.trim() } }}},[_c('v-icon',{staticClass:"mt-n1",attrs:{"small":"","color":"primary","left":""}},[_vm._v(" mdi-account ")]),_c('span',{staticClass:"primary--text font-weight-bold",domProps:{"textContent":_vm._s(item.ven_des)}})],1)]}},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 py-2 px-4",attrs:{"depressed":"","text":"","color":"blue-grey","min-width":"23px","height":"31px"},on:{"click":function($event){return _vm.downloadHorario(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","color":"secondary","size":"20"}},[_vm._v("mdi-file-download-outline")]),_vm._v(" Horario ")],1)]}}],null,true)},[_c('span',[_vm._v("Descargar Horario")])]),_c('v-divider',{staticStyle:{"margin-top":"4px !important"},attrs:{"inset":"","vertical":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 pa-2",attrs:{"depressed":"","text":"","color":"blue-grey","min-width":"23px","height":"31px","to":{
                    name: 'Vendedor',
                    params: { cod: item.co_ven.trim() }
                  }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","size":"20"}},[_vm._v("mdi-text-box-check-outline")]),_vm._v(" Detalles ")],1)]}}],null,true)},[_c('span',[_vm._v("Ver Detalles")])])],1)]}},{key:"item.portafolios",fn:function(ref){
                  var item = ref.item;
return _vm._l((item.portafolios),function(item,i){return _c('v-chip',{staticClass:"mr-2",attrs:{"label":"","color":item === 'S/P' ? 'red' : 'green',"x-small":"","dark":""}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item)+" ")])])})}},{key:"loading",fn:function(){return [_c('v-skeleton-loader',_vm._b({attrs:{"type":"table-tbody"}},'v-skeleton-loader',_vm.attrs,false))]},proxy:true}])})],1),_c('v-snackbar',{attrs:{"timeout":-1,"value":_vm.loadDownload,"right":"","color":"primary","elevation":"24"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"icono"}}),_c('span',{staticClass:"ml-4"},[_vm._v("Descargando Horario")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }