<template>
  <div>
    <v-row class="pa-0 ma-0">
      <v-col cols="12" class="pa-0">
        <v-data-iterator
          :items="vendedores"
          :items-per-page="10"
          :search="search"
          :loading="loading"
          v-if="$vuetify.breakpoint.mobile"
        >
          <template v-slot:default="{ items }">
            <template v-for="(vendedor, i) in items">
              <mobile-vendedores :vendedor="vendedor" :key="'mobilev-' + i">
                <template #action>
                  <div class="d-flex justify-end">
                    <v-btn
                      depressed
                      text
                      color="blue-grey"
                      class="mx-2 py-4 px-2"
                      min-width="23px"
                      height="31px"
                      :to="{
                        name: 'Vendedor',
                        params: { cod: vendedor.co_ven.trim() }
                      }"
                    >
                      <v-icon left color="secondary" size="20"
                        >mdi-file-download-outline</v-icon
                      >
                      Horarios
                    </v-btn>
                    <v-divider
                      inset
                      vertical
                      style="margin-top: 4px !important"
                    />
                    <v-btn
                      depressed
                      text
                      color="blue-grey"
                      class="mx-2 py-4 px-2"
                      min-width="23px"
                      height="31px"
                      :to="{
                        name: 'Vendedor',
                        params: { cod: vendedor.co_ven.trim() }
                      }"
                    >
                      <v-icon left size="20">
                        mdi-text-box-check-outline
                      </v-icon>
                      Detalles
                    </v-btn>
                  </div>
                </template>
              </mobile-vendedores>
            </template>
          </template>
          <template #loading>
            <v-skeleton-loader
              type="list-item-two-line, list-item, list-item-two-line"
            ></v-skeleton-loader>
          </template>
        </v-data-iterator>
        <v-data-table
          :headers="dataheaders"
          :items="vendedores"
          :search="search"
          :loading="loading"
          disable-pagination
          hide-default-footer
          v-else
        >
          <template v-slot:item.ven_des="{ item }">
            <v-list-item
              :to="{ name: 'Vendedor', params: { cod: item.co_ven.trim() } }"
            >
              <v-icon small color="primary" left class="mt-n1">
                mdi-account
              </v-icon>
              <span
                class="primary--text font-weight-bold"
                v-text="item.ven_des"
              />
            </v-list-item>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="d-flex justify-end">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    text
                    color="blue-grey"
                    class="mx-2 py-2 px-4"
                    min-width="23px"
                    height="31px"
                    v-bind="attrs"
                    v-on="on"
                    @click="downloadHorario(item)"
                  >
                    <v-icon left color="secondary" size="20"
                      >mdi-file-download-outline</v-icon
                    >
                    Horario
                  </v-btn>
                </template>
                <span>Descargar Horario</span>
              </v-tooltip>
              <v-divider inset vertical style="margin-top: 4px !important" />
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    text
                    color="blue-grey"
                    class="mx-2 pa-2"
                    min-width="23px"
                    height="31px"
                    v-bind="attrs"
                    v-on="on"
                    :to="{
                      name: 'Vendedor',
                      params: { cod: item.co_ven.trim() }
                    }"
                  >
                    <v-icon left size="20">mdi-text-box-check-outline</v-icon>
                    Detalles
                  </v-btn>
                </template>
                <span>Ver Detalles</span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:item.portafolios="{ item }">
            <v-chip
              label
              class="mr-2"
              :color="item === 'S/P' ? 'red' : 'green'"
              x-small
              dark
              v-for="(item, i) in item.portafolios"
            >
              <span class="font-weight-medium"> {{ item }} </span>
            </v-chip>
          </template>

          <template #loading>
            <v-skeleton-loader
              v-bind="attrs"
              type="table-tbody"
            ></v-skeleton-loader>
          </template>
        </v-data-table>
      </v-col>
      <v-snackbar
        :timeout="-1"
        :value="loadDownload"
        right
        color="primary"
        elevation="24"
      >
        <v-progress-circular indeterminate color="icono"></v-progress-circular>
        <span class="ml-4">Descargando Horario</span>
      </v-snackbar>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'DataAccounts',
  components: {
    MobileVendedores: () =>
      import(
        /* webpackChunkName: "mobile-vendedores" */
        './MobileVendedores.vue'
      )
  },
  props: {
    headers: {
      type: Array,
      default: () => []
    },
    vendedores: {
      type: Array,
      default: () => []
    },
    search: String,
    loading: Boolean
  },
  data: () => ({
    attrs: {
      boilerplate: false,
      loading: true
    },
    loadDownload: false
  }),
  computed: {
    dataheaders() {
      return this.headers.filter((item) => item.active)
    }
  },
  methods: {
    async downloadHorario(vend) {
      this.loadDownload = true
      try {
        const { data } = await this.$api.get(
          `vendedores/${vend.co_ven}/exporthorario`,
          { responseType: 'blob' }
        )

        var horarioURL = window.URL.createObjectURL(new Blob([data]))
        var horarioLink = document.createElement('a')

        horarioLink.href = horarioURL
        horarioLink.setAttribute('download', `HORARIO ${vend.ven_des}.xlsx`)
        document.body.appendChild(horarioLink)
        horarioLink.click()
        horarioLink.remove()
      } catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener El horario del Vendedor.',
          'error'
        )
      }
      this.loadDownload = false
    }
  }
}
</script>
